import { hot } from 'react-hot-loader/root'
import React from 'react'
import { IntlProvider } from 'react-intl'
import { EPProvider } from '@elparking/components'
import convertConstantToEPProviderFormat from 'commons/js/util/coverterGlobalConfig'
import { messages } from 'commons/js/messages'
import { defaultLocale, currentLocale } from 'commons/js/locale'
import LoginButtonWithModal from './LoginButtonWithModal'
import theme from './theme'

const App = (props) => {
    return <IntlProvider
      locale={currentLocale}
      defaultLocale={defaultLocale}
      messages={messages[currentLocale]}>
        <EPProvider theme={theme} {...convertConstantToEPProviderFormat()}>
            <LoginButtonWithModal {...props} />
        </EPProvider>
    </IntlProvider>
}

export default hot(App)
