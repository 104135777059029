import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, useConfig } from '@elparking/components'
import * as api from 'commons/js/api'
import Routes from 'commons/js/routes/routes'
import { LoginButton } from '@elparking/login'
import Logo from 'commons/js/ui/Logo'
import { getFlag } from 'commons/js/constants'
import { navigateTo, reload } from 'commons/js/services/navigate'
import LoginModal from 'commons/js/components/LoginModal'

const LoginButtonWithModal = (props) => {
    const { flavor } = useConfig()
    const [registerSuccessMessage, setRegisterSuccessMessage] = useState(false)
    const isKeycloakEnabled = getFlag('isKeycloakEnable')
    const [defaultShow, setDefaultShow] = useState(false)
    const onRegisterSuccess = () => {
      if (isKeycloakEnabled) {
        setRegisterSuccessMessage(true)
        setDefaultShow(true)
      } else {
        props.redirect ? navigateTo(props.redirect) : reload()
      }
    }

    return <LoginButton
      key={defaultShow}
      loginModalComponent={LoginModal}
      logo={
          <a href={Routes.homePath()}>
              <Logo height='32px' />
          </a>
      }
      theme={flavor}
      color='gray80'
      fontWeight='book'
      fontSize='large'
      buttonComponent={Button.unstyled}
      registerSuccessMessage={registerSuccessMessage}
      getUser={api.getUser}
      userMenu={[]}
      mobileMenuWithoutLogin={[]}
      defaultShow={defaultShow}
      onRegisterSuccess={onRegisterSuccess}
      showCloseButton
      onShowModal={() => setRegisterSuccessMessage(false)}
      {...props}
    />
}

LoginButtonWithModal.propTypes = {
  redirect: PropTypes.string,
}

export default LoginButtonWithModal
