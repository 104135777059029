import styled from 'styled-components'
import PropTypes from 'prop-types'
import { transparentize } from 'polished'
import { display, height, minHeight, maxWidth, space, width, lineHeight, borderRadius } from 'commons/js/theme/styles'

import {
    themeColor,
} from '@elparking/components'

const borderColor = (props) => themeColor(props.color)(props)

const backgroundColor = (props) => {
    const { color, background } = props

    return background
        ? themeColor(background)(props)
        : transparentize(0.6, themeColor(color)(props))
}

const HighlightedBlock = styled.div.attrs(props => ({'data-test': props.dataTest}))`
    background-color: ${backgroundColor};
    padding: 0.5em;
    border: 1px solid ${borderColor};
    ${lineHeight}
    ${maxWidth}
    ${width}
    ${height}
    ${minHeight}
    ${display}
    ${space}
    ${borderRadius}
`

HighlightedBlock.propTypes = {
    color: PropTypes.string,
    background: PropTypes.string,
}

HighlightedBlock.defaultProp = {
    color: 'accent',
    background: null,
    display: 'block',
    lineHeight: 'auto',
    p: '0.5em',
}

export default HighlightedBlock
